import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql , Link} from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const Story = () => (
  <StaticQuery
    query={graphql`
      query {
        art_build: file(
          sourceInstanceName: { eq: "inspiration" }
          name: { eq: "lydia_workshop" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="lydia_story" style={{backgroundColor: '#183649'}}>
        <Container>
          <Grid>
            <Text>
              <Link to={`/about`}><h1>Lydia's story</h1></Link>
              <p>
              Born, raised and inspired in Cornwall. All my creations are brought to life in my studio in Bristol.
              <br />
              <br />
                Bespoke items with cornish heritage.
              </p>
            </Text>
            <Art>
              <Img fluid={data.art_build.childImageSharp.fluid} alt='lydia in her studio'/>
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 60px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

const Text = styled.div`
  max-width: 380px;
  width: 100%;
  text-align: center;

  h1 {
    margin-bottom: 20px;
    font-family: ${props => props.theme.font.secondary};
  }

  a{
    text-decoration: none;
    color: ${props => props.theme.color.white.regular};
    font-family: ${props => props.theme.font.secondary};
  }

  p{
    color: ${props => props.theme.color.white.regular};
  }

  a:hover {
    cursor: pointer;
  }
`;

export default Story;