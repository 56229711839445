import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Button from '@components/common/Button';

import { Section, Container } from '@components/global';

const Creation = () => (
  <StaticQuery
    query={graphql`
      query {
        allEtsyListing {
          nodes {
            id
            title
            price
            taxonomy_path
            tags
            url
            childEtsyListingImage {
              childFile {
                childImageSharp {
                  fluid(maxWidth: 760, maxHeight: 760) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        },
        allFile(filter: { sourceInstanceName: { eq: "inspiration" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="inspiration"  
      style={{height: '80%'}}>
        <Container style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid>
                <div key={'img1'}>
                  <a href={data.allEtsyListing.nodes[4].url}>
                  <Img  fluid={data.allEtsyListing.nodes[4].childEtsyListingImage.childFile.childImageSharp
                          .fluid}  />
                          </a>
                </div>
                <div key={'img2'}>
                <a href={data.allEtsyListing.nodes[1].url}>
                  <Img fluid={data.allEtsyListing.nodes[1].childEtsyListingImage.childFile.childImageSharp
                          .fluid}  />
                          </a>
                </div>
                <div key={'img3'}>
                <a href={data.allEtsyListing.nodes[2].url}>
                  <Img fluid={data.allEtsyListing.nodes[2].childEtsyListingImage.childFile.childImageSharp
                          .fluid} />
                          </a>
                </div>
                <div key={'img4'}>
                <a href={data.allEtsyListing.nodes[3].url}>
                  <Img fluid={data.allEtsyListing.nodes[3].childEtsyListingImage.childFile.childImageSharp
                          .fluid} />
                          </a>
                </div>
            
            </Grid>
          
        </Container>
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button site={'shop'} text={'Visit Shop'}/>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-template-rows: min-content;
  grid-gap: 30px;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-bottom: 50px;


  @media (max-width: ${props => props.theme.screen.lg}) {
    justify-content: center;
    grid-gap: 24px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    grid-gap: 24px;
  }
`;

export default Creation;