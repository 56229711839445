import React from 'react';

import { Link } from 'gatsby';
import {ButtonWrap} from './style'

const Button = ({ text, site }) => (
    <ButtonWrap
        role="button"
        tabIndex="0"
      >
        <Link to={`/${site}`}>{text}</Link>
      </ButtonWrap>
);

export default Button;
