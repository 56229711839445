import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';


const Hero = (props) => (
    <StaticQuery
    query={graphql`
      query {
        ocean_image: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ocean" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper>
        <BackgroundImage
        fluid={data.ocean_image.childImageSharp.fluid}
        >
          <Text>Hand-crafted jewellery inspired by the sea</Text>
            
        </BackgroundImage>
      </HeaderWrapper>
)}
/>
);


const HeaderWrapper = styled.header`

  background-color: ${props => props.theme.color.primary};

  @media (max-width: ${props => props.theme.screen.md}) {
    // padding-top: 128px;
  }
`;


const Text = styled.div`
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-family: ${props => props.theme.font.secondary};
  color: ${props => props.theme.color.white.regular};
  text-shadow: 1px 1px 2px #183649;
  padding: 50px;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }
`;

export default Hero;