import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Footer from '@common/Footer';

import Hero from '@sections/Hero';
import Creation from '@sections/Creation';
import Story from '@sections/Story';
import TempInstagram from '@sections/TempInstagram';

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Hero />
    <Creation />
    <Story />
    <TempInstagram />
    <Footer />
  </Layout>
);

export default IndexPage;
