import React from 'react';
import styled from 'styled-components';
import ExternalLink from '@common/ExternalLink';

import { Section, Container } from '@components/global';

const TempInstagram = () => (
      <Section id="instagram">
        <Container style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button role="button"
        tabIndex="0">
          <h2>
        <ExternalLink key={'instagram-link'} href={'https://www.instagram.com/lydiawoodjewellery/'}>
                  Follow me on Instagram
                </ExternalLink>
                </h2>
        </Button>
        </Container>
      </Section>
);

const Button = styled.div`

    margin-top: 30px;
  &:hover {
    cursor: pointer;
  }

 a{
background-color: #183649;
display:inline-block;
 padding:0.6em 1.4em;
 margin:0 0.3em 0.3em 0;
 border-radius:0.2em;
font-size: 30px;
 box-sizing: border-box;
 text-decoration:none;
 color:#FFFFFF;

 box-shadow:inset 0 -0.6em 1em -0.35em rgba(0,0,0,0.17),inset 0 0.6em 2em -0.3em rgba(255,255,255,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);
 text-align:center;
 position:relative;
}
a :hover{
 box-shadow:inset 0 0.6em 2em -0.3em rgba(0,0,0,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);
}
@media all and (max-width:30em){
 a {
  display:block;
  margin:0.4em auto;
 }
`;


export default TempInstagram;